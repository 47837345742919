(function(){
	var videoIncrivel = $('.video-incrivel');
	// videoIncrivel.click(function(){
	// 	var htmlPronto = $(this).find('template').html();
	// 	var bgVideo	   = $(this).data('video');
	// 	$(this).html(htmlPronto);
	// 	$(this).addClass('ativado');
	// 	$(this).find('iframe').attr('src',bgVideo);
	// 	$(this).attr('style','');
	// });
	videoIncrivel.each(function(){

		$(this).click(function(){

			var attr = $(this).attr('data-video');

			console.log(attr);

			$('#modal-videos').find('iframe').attr('src',attr);

		});
		
	});

	$('#modal-videos').click(function(){

		$(this).find('iframe').attr('src','');

	});

})();