(function(){
	var pai 		= $('.custom-tab-js');
	var ativadores 	= pai.find('[data-ativador="true"]');
	ativadores.each(function(){
		$(this).click(function(){
			var esse = $(this);
			ativadores.removeClass('active');
			ativadores.each(function(){
				if($(this).attr('data-tab') == esse.attr('data-tab')){
					$(this).addClass('active');
				}else{
					$(this).removeClass('active');
				}
			});
		});
	});
})();