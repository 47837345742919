(function(){

	var vou = $('[data-passar]');

	vou.each(function(){

		$(this).click(function(){
			event.preventDefault();
			var dahora = $(this).attr('data-passar');
			var sou = $(this).attr('href');
			$('#lightbox-imagem-grande a').attr('href',sou);
			$('#lightbox-imagem-grande img').attr('src',dahora);

		});

	});

})();