var alvoClass = document.querySelector('.topo');

var height = $(alvoClass).innerHeight();

$(window).scroll(function(){
	var body = document.body;

	var posicaoAtual = body.getBoundingClientRect().top;

	if($(this).scrollTop() > height){
		$(alvoClass).addClass('fx');
	}else{
		$(alvoClass).removeClass('fx');
	}

});

